.form-lede {
  align-items: center;
  display: flex;
  margin-bottom: 16px;

  color: var(--text-color);
  font-family: var(--font-family--body);
  font-size: 16px;
  font-style: normal;
  font-weight: bold;
  line-height: 20px;

  svg {
    width: 22px;
    fill: var(--primary-background);
    margin-right: 6px;
  }
}

.form-control {
  background-clip: padding-box;
  display: block;
  min-height: 32px;
  position: relative;
  width: 100%;

  background-color: var(--input-background-color);
  border: 1px solid var(--input-border-color);
  border-radius: 4px;

  input, textarea {
    display: block;
    outline: none;
    padding: 3px 9px;
    width: 100%;

    color: var(--input-color);
    font-family: var(--font-family--body);
    font-size: var(--font-size--14);
    font-weight: var(--font-weight--regular);
    line-height: 24px;

    background-color: transparent !important;
    border: none;
    border-radius: 4px;
  }

  // Try to override browser autofill
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px var(--input-background-color) inset !important;
  }

  /* ---------- States ---------- */

  &:hover {
    background-color: var(--input-background-color--hover);
    border-color: var(--input-border-color--hover);

    input {
      color: var(--input-color--hover);
    }
  }

  &:focus-within {
    background-color: var(--input-background-color--focus);
    border-color: var(--input-border-color--focus);

    input {
      color: var(--input-color--focus);
    }
  }

  &:disabled, &.disabled {
    background-color: var(--input-background-color--disabled);
    border-color: var(--input-border-color--disabled);

    input {
      color: var(--input-color--disabled);
    }
  }

  &.invalid {
    border-color: var(--accent-color--red);
  }

  /* ---------- Sizes ---------- */

  &.small {
    height: 28px;

    &.invalid::after {
      top: 5px;
    }

    input {
      font-size: var(--font-size--12);
      line-height: 1.3333;
      padding: 5.5px 10px;

      &:disabled {
        padding: 6.5px 11px;
      }
    }
  }

  &.large {
    height: 40px;

    &.invalid::after {
      top: 11px;
    }

    input {
      font-size: 14px;
      line-height: 24px;
      padding: 7px 10px;

      &:disabled {
        padding: 8px 11px;
      }
    }
  }
}

.form-control-plaintext {
  display: block;
  height: 32px;
  padding: 9px 11px;
  width: 100%;

  font-family: var(--font-family--body);
  font-size: var(--font-size--12);
  font-weight: var(--font-weight--regular);
  line-height: 1.3333;

  background-color: transparent;
  border: 0 solid transparent;
  color: var(--input-color);

  &.small {
    height: 28px;
    font-size: var(--font-size--12);
    line-height: 1.3333;
    padding: 6.5px 11px;
  }

  &.large {
    height: 40px;
    font-size: var(--font-size--14);
    line-height: 1.7143;
    padding: 8px 11px;
  }
}

.form-group {
  margin-bottom: 0.5rem;

  label {
    color: var(--color-grey-800);
    display: block;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.015em;
    line-height: 16px;
    margin-bottom: 8px;
    text-transform: uppercase;

    &.required:after {
      color: var(--accent-color--red);
      content: '*';
      margin-left: 2px;
    }

    &.invalid {
      color: var(--accent-color--red);
    }
  }
}

.form-message {
  font-size: 12px;
  line-height: 18px;
  margin-top: 4px;
  min-height: 18px;
  position: relative;

  .error {
    color: var(--accent-color--red);

    svg {
      height: 16px;
      width: 16px;
      fill: var(--accent-color--red);
      margin: 1px 4px 1px 0;
    }
  }

  .info {
    color: var(--color-grey-700);

    svg {
      height: 16px;
      width: 16px;
      fill: var(--color-primary-blue);
      margin: 1px 4px 1px 0;
    }
  }
}

.row {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

textarea.form-control {
  height: auto;
}
