// For menus that are right-oriented
.pki-dropdown.menu-right .pki-dropdown-menu {
  right: 12px;
}

.pki-chip .pki-chip-delete-icon-right {
  height: 16px;
  width: 16px;
}

.pki-chip-delete-icon-right svg {
  height: 16px;
  width: 16px;
  stroke: var(--color-white);
}

.form-message {
  font-size: 12px;
  line-height: 18px;
  margin-top: 4px;
  min-height: 18px;
  position: relative;
}

.form-message .error {
  align-items: flex-start;
  color: var(--accent-color--red);
  display: flex;
}

.form-message .error svg {
  height: 15px;
  width: 15px;
  fill: var(--accent-color--red);
  margin: 0 4px 0 0;
}
