@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root {
  // Fonts
  --font-family--body: 'Inter', sans-serif;
  --font-family--data: 'Inter', sans-serif;
  --font-family--headline: 'Inter', sans-serif;

  // Font Sizes
  --font-size--base: 16px;
  --font-size--22: calc(var(--font-size--base) * 1.375); // 18px
  --font-size--18: calc(var(--font-size--base) * 1.125); // 18px
  --font-size--16: var(--font-size--base); // 16px
  --font-size--14: calc(var(--font-size--base) * 0.875); // 14px
  --font-size--13: calc(var(--font-size--base) * 0.8125); // 13px
  --font-size--12: calc(var(--font-size--base) * 0.75); // 12px
  --font-size--11: calc(var(--font-size--base) * 0.6875); // 11px
  --font-size--10: calc(var(--font-size--base) * 0.625); // 10px
  --font-size--9: calc(var(--font-size--base) * 0.5625); // 9px

  --font-size--display-1: calc(var(--font-size--base) * 4); // 64px (use with font-weight bold)
  --font-size--display-2: calc(var(--font-size--base) * 3.5); // 56px (use with font-weight regular)

  --font-size--h1: calc(var(--font-size--base) * 2.5); // 40px (use with line height 1.2)
  --font-size--h2: calc(var(--font-size--base) * 2); // 32px (use with line height 1.375)
  --font-size--h3: calc(var(--font-size--base) * 1.75); // 28px (use with line height 1.3571)
  --font-size--h4: calc(var(--font-size--base) * 1.5); // 24px (use with line height 1.3333)
  --font-size--h5: calc(var(--font-size--base) * 1.25); // 20px (use with line height 1.4)
  --font-size--h6: calc(var(--font-size--base) * 1.125); // 18px (use with line height 1.3333)

  --font-size--lead: var(--font-size--base); // Use with font-weight bold
  --font-size--lead-small: var(--font-size--14); // Use with font weight bold

  --font-size--body: var(--font-size--14); // 14px
  --font-size--body-large: var(--font-size--base); // 16px

  --font-size--data: calc(var(--font-size--base) * 0.75); // 12px
  --font-size--data--smal: calc(var(--font-size--base) * 0.6875); // 11px

  // Line Heights
  --line-height--base: 1.2;
  --line-height--body: 24px; // Fixed height in order to align both regular and small body text
  --line-height--display-1: 1.4;
  --line-height--display-2: 1.2143;

  // Font Weights
  --font-weight--extra-black: 950;
  --font-weight--black: 900;
  --font-weight--extra-bold: 800;
  --font-weight--bold: 700;
  --font-weight--semi-bold: 600;
  --font-weight--medium: 500;
  --font-weight--regular: 400;
  --font-weight--light: 300;
  --font-weight--extra-light: 200;
  --font-weight--thin: 100;

  // Colors
  --color-brand-blue: rgba(37, 79, 158, 1);
  --color-brand-dark: rgba(18, 17, 15, 1);
  --color-brand-grey: rgba(148, 149, 149, 1);

  --color-black: #000;
  --color-black-text: rgba(51, 51, 51, 1); // #333333
  --color-grey-900: rgba(33, 41, 52, 1);
  --color-grey-800: rgba(74, 79, 93, 1);
  --color-grey-700: rgba(90, 97, 117, 1);
  --color-grey-600: rgba(109, 120, 149, 1);
  --color-grey-500: rgba(132, 143, 170, 1); // Neutral
  --color-grey-400: rgba(171, 178, 195, 1);
  --color-grey-300: rgba(193, 199, 210, 1);
  --color-grey-200: rgba(209, 213, 223, 1);
  --color-grey-100: rgba(230, 233, 240, 1);
  --color-grey-50: rgba(245, 246, 250, 1);
  --color-white: #fff;

  --color-blue-700: rgba(25, 63, 132, 1);
  --color-blue-600: rgba(19, 83, 202, 1);
  --color-blue-400: rgba(69, 144, 247, 1);
  --color-blue-300: rgba(118, 166, 254, 1);
  --color-blue-200: rgba(174, 202, 255, 1);
  --color-blue-150: rgba(205, 219, 244, 1);
  --color-blue-100: rgba(226, 236, 254, 1);
  --color-blue-50: rgba(241, 249, 255, 1);

  --color-green: rgba(44, 133, 2, 1);
  --color-green-700: rgba(10, 113, 85, 1);
  --color-orange-700: rgba(210, 57, 15, 1);
  --color-red-400: rgba(248, 94, 96, 1);

  // Other Colors
  --accent-color--green: rgba(2, 138, 15, 1); // Success
  --accent-color--orange: rgba(240, 107, 0, 1); // Warning
  --accent-color--red: rgba(183, 18, 52, 1); // Danger
  --accent-color--purple: rgba(139, 99, 244, 1); // New

  --background-color--light-100: rgba(245, 246, 250, 1);
  --background-color--light-200: rgba(242, 243, 248, 1);
  --background-color--light-300: rgba(227, 229, 237, 1);
  --background-color--light-400: rgba(194, 203, 220, 1);

  --overlay-background-color--light: rgba(33, 41, 52, 0.08);
  --overlay-background-color--dark: rgba(33, 41, 52, 0.3);
  --overlay-background-color--darker: rgba(33, 41, 52, 0.4);
  --overlay-dialog: rgba(0, 0, 0, 0.5);

  --color-primary-blue: rgba(4, 111, 210, 1);
  --color-primary-ink: rgba(33, 41, 52, 1);

  // Shadows
  --box-shadow--800: 0px 8px 28px -6px rgba(24, 39, 75, 0.12), 0px 18px 88px -4px rgba(24, 39, 75, 0.14);
  --box-shadow--700: 0px 8px 22px -6px rgba(24, 39, 75, 0.12), 0px 14px 64px -4px rgba(24, 39, 75, 0.12);
  --box-shadow--600: 0px 8px 18px -6px rgba(24, 39, 75, 0.12), 0px 12px 42px -4px rgba(24, 39, 75, 0.12);
  --box-shadow--500: 0px 6px 14px -6px rgba(24, 39, 75, 0.12), 0px 10px 32px -4px rgba(24, 39, 75, 0.1);
  --box-shadow--400: 0px 6px 12px -6px rgba(24, 39, 75, 0.12), 0px 8px 24px -4px rgba(24, 39, 75, 0.08);
  --box-shadow--300: 0px 6px 8px -6px rgba(24, 39, 75, 0.12), 0px 8px 16px -6px rgba(24, 39, 75, 0.08);
  --box-shadow--200: 0px 4px 6px -4px rgba(24, 39, 75, 0.12), 0px 8px 8px -4px rgba(24, 39, 75, 0.08);
  --box-shadow--100: 0px 2px 4px -2px rgba(24, 39, 75, 0.12), 0px 4px 4px -2px rgba(24, 39, 75, 0.08);
}
