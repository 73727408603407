*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
  margin: 0;
  overflow: hidden;
  padding: 0;
}

#root {
  background-color: var(--background-color);
  color: var(--text-color);
  font-family: var(--font-family--body);
  font-size: var(--font-size--14);
  font-weight: var(--font-weight--regular);
  line-height: 1.25;

  &.theme-dark,
  &.theme-light {
    transition: all 0.5s;
  }

  .pki-title {
    font-size: var(--font-size--h4);
    font-weight: var(--font-weight--regular);
    padding: 0;
    margin: 0;
  }

  .pki-lede {
    align-items: baseline;
    display: flex;
  }

  .pki-button-lede {
    align-items: baseline;
    display: flex;
    justify-content: space-between;
  }

  .pki-back-btn {
    cursor: pointer;
    height: 16px;
    width: 16px;
  }

  //.pki-tooltip {
  //  color: var(--text-color);
  //  font-size: var(--font-size--11);
  //  line-height: 16px;
  //  margin-top: 8px;
  //  padding-left: 20px;
  //  position: relative;
  //
  //  &:before {
  //    position: absolute;
  //    left: 0;
  //    top: 0;
  //    height: 16px;
  //    width: 16px;
  //
  //    background-color: var(--color-blue-400);
  //    mask-image: url('/assets/info_24px.svg');
  //    mask-position: center center;
  //    mask-repeat: no-repeat;
  //    mask-size: 16px 16px;
  //    content: '';
  //  }
  //}
}

/* ---------- General content styles ---------- */

header,
.header {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 8px;

  .pki-header-slot {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;

    &.end {
    }
  }

  .pki-page-title {
    flex: 0 1 auto;
    font-size: var(--font-size--h4);
    margin-right: 12px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100%;
}

.content-inner-container {
  background-color: var(--background-color);
  border-radius: 16px;
  display: flex;
  flex: 2 1 auto;
  justify-content: space-between;
  gap: 16px;
  margin: 19px 0 28px;
  padding: 24px;

  section {
    border-radius: 16px;

    &.list {
      background-color: var(--background-color);
      border-color: var(--border-color);
      border-style: solid;
      border-width: 1px;
      flex: 1.22 1 0; // 55%
    }

    &.item {
      background-color: var(--color-grey-50);
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 24px;
      flex: 1 1 0; // 45%

      &.form {
        background-color: var(--background-color);
        border-color: var(--border-color);
        border-style: solid;
        border-width: 1px;
        padding: 16px;
        justify-content: flex-start;

        .form-header {
          align-items: center;
          display: flex;
          flex-wrap: wrap;
          gap: 8px;
          justify-content: space-between;
          margin-bottom: 12px;

          .form-title {
            font-weight: var(--font-weight--bold);
            flex: 2 1 0;
            white-space: nowrap;
          }

          .form-actions {
            display: flex;
            gap: 8px;
          }
        }
      }

      .caption-container {
        text-align: center;
        fill: var(--color-blue-300);

        .caption {
          color: var(--color-grey-700);
          font-weight: var(--font-weight--bold);
          margin-top: 24px;
        }
      }
    }
  }
}

/* ---------- Column auto-layout styles ---------- */

.col-container {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  justify-content: space-between;

  &.bottom-border {
    border-bottom: 1px solid var(--border-color);
    margin-bottom: 24px;
  }

  .col {
    flex: 1 1 0;
    min-width: 100px;
  }

  .col2 {
    flex: 2 2 0;
    min-width: 100px;
  }
}

.filter-group {
  font-family: var(--font-family--body);
  font-size: var(--font-size--12);
  line-height: 16px;
  text-transform: uppercase;
}

//.form-control.search {
//  input {
//    padding-right: 33px;
//  }
//
//  &::after {
//    margin: auto 8px;
//    position: absolute;
//    right: 0;
//    top: 0;
//    height: 100%;
//    width: 16px;
//
//    background-image: url('/assets/search_24px.svg');
//    background-position: center center;
//    background-repeat: no-repeat;
//    background-size: 16px 16px;
//    content: '';
//    fill: var(--color-grey-700);
//  }
//}

/* ---------- No Access and Not Found pages ---------- */

.single-page {
  padding-top: 40px;
  text-align: center;

  .header-container {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .alert-icon {
    color: var(--accent-color--red);
    height: 42px;
    width: 42px;
    margin-right: 8px;

    svg {
      fill: var(--accent-color--red);
    }
  }
}

