.pki-data-grid {
  height: 100%;
  width: 100%;

  .MuiDataGrid-root {
    border: none;
    font-family: var(--font-family--body);
    font-weight: var(--font-weight--regular);
    font-size: var(--font-size--14);

    .MuiDataGrid-columnHeaders {
      background-color: var(--color-grey-50);
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      // font-size: var(--font-size--12);
      // line-height: 1.2 !important;
      min-height: 0 !important;

      .MuiDataGrid-columnHeader {
        padding: 0px 12px;

        &:focus {
          outline: 0;
        }

        .MuiDataGrid-menuIcon {
          font-size: var(--font-size--12);
        }

        .MuiDataGrid-columnSeparator {
          min-height: 0 !important;
          visibility: hidden;

          .MuiSvgIcon-fontSizeMedium {
            height: 16px;
            width: 16px;
          }
        }

        .MuiDataGrid-columnHeaderTitleContainer {
          padding: 0;

          .MuiDataGrid-columnHeaderTitle {
            font-weight: var(--font-weight--regular);
          }
        }

        .MuiButtonBase-root {
          font-size: var(--font-size--12);
          padding: 0;

          .MuiSvgIcon-root {
            height: 16px;
            width: 16px;
          }
        }
      }
    }

    .MuiDataGrid-row {
      align-items: center;
      border-bottom-color: var(--color-grey-100);
      border-bottom-style: solid;
      border-bottom-width: 1px;
      min-height: 0 !important;
      padding-left: 4px !important;

      .MuiDataGrid-cell {
        border: none;
        // min-height: 0px !important;  Note: to use autoPageSize, the height must stay 52px
        outline: 0;
        padding: 6px 8px;
      }

    }

    .MuiDataGrid-footerContainer {
      background-color: var(--color-grey-50);
      border-top: 1px solid var(--color-grey-200);
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
      min-height: 0 !important;
      padding: 0 18px;

      .MuiDataGrid-selectedRowCount {
        line-height: 2;
        margin: 0;
      }

    }
  }
}

.MuiDataGrid-menu {
  .MuiPaper-root {
    border-color: var(--border-color);
    border-style: solid;
    border-width: 1px;
    box-shadow: none;
    filter: drop-shadow(0px 3px 8px rgba(0, 0, 0, 0.2));
  }

  .MuiMenuItem-root {
    font-size: var(--font-size--12);
    line-height: 24px;
    padding: 0 12px;
  }
}
