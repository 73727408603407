:root[data-theme=theme-light] {
  --text-color: var(--color-black-text);
  --background-color: var(--color-white);
  --border-color: var(--color-grey-200);

  // Defaults
  --default-background: var(--color-white);
  --default-color: var(--color-black);
  --default-border-color: var(--color-black);
  --default-border-radius: 8px;
  --default-border-width: 1px;
  --default-border-width--focus: 1px;

  // Input field
  --input-color: var(--color-grey-900);
  --input-background-color: var(--color-grey-50);
  --input-border-color: var(--color-grey-300);
  // Input field hover
  --input-color--hover: var(--color-primary-ink);
  --input-background-color--hover: var(--color-grey-50);
  --input-border-color--hover: var(--color-primary-ink);
  // Input field focus
  --input-color--focus: var(--color-primary-ink);
  --input-background-color--focus: var(--color-white);
  --input-border-color--focus: var(--color-primary-ink);
  // Input field disabled
  --input-color--disabled: var(--color-grey-900);
  --input-background-color--disabled: var(--color-grey-50);
  --input-border-color--disabled: var(--color-grey-50);

  // Primary base
  --primary-background: var(--color-primary-blue);
  --primary-color: var(--color-white);
  --primary-border-color: var(--color-primary-blue);
  // Primary hover
  --primary-background--hover: var(--color-blue-600);
  --primary-color--hover: var(--color-white);
  --primary-border-color--hover: var(--color-blue-600);
  // Primary focus
  --primary-background--focus: var(--color-blue-700);
  --primary-color--focus: var(--color-white);
  --primary-border-color--focus: var(--color-blue-700);
  // Primary inactive
  --primary-background--inactive: var(--color-blue-200);
  --primary-color--inactive: var(--color-white);
  --primary-border-color--inactive: var(--color-blue-200);

  // Secondary
  --secondary-background: var(--color-white);
  --secondary-color: var(--color-primary-blue);
  --secondary-border-color: var(--color-primary-blue);
  // Secondary hover
  --secondary-background--hover: var(--color-grey-50);
  --secondary-color--hover: var(--color-primary-blue);
  --secondary-border-color--hover: var(--color-blue-600);
  // Secondary focus
  --secondary-background--focus: var(--color-white);
  --secondary-color--focus: var(--color-primary-blue);
  --secondary-border-color--focus: var(--color-blue-600);
  // Secondary inactive
  --secondary-background--inactive: var(--color-white);
  --secondary-color--inactive: var(--color-blue-200);
  --secondary-border-color--inactive: var(--color-blue-200);

  // Tertiary
  --tertiary-background: var(--color-blue-100);
  --tertiary-color: var(--color-primary-blue);
  --tertiary-border-color: var(--color-blue-100);
  // Tertiary hover
  --tertiary-background--hover: var(--color-blue-150);
  --tertiary-color--hover: var(--color-primary-blue);
  --tertiary-border-color--hover: var(--color-blue-150);
  // Tertiary focus
  --tertiary-background--focus: var(--color-blue-100);
  --tertiary-color--focus: var(--color-primary-blue);
  --tertiary-border-color--focus: var(--color-blue-100);
  // Tertiary inactive
  --tertiary-background--inactive: var(--color-blue-50);
  --tertiary-color--inactive: var(--color-blue-200);
  --tertiary-border-color--inactive: var(--color-blue-50);
}
