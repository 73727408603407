@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
:root {
  --font-family--body: "Inter", sans-serif;
  --font-family--data: "Inter", sans-serif;
  --font-family--headline: "Inter", sans-serif;
  --font-size--base: 16px;
  --font-size--22: calc(var(--font-size--base) * 1.375);
  --font-size--18: calc(var(--font-size--base) * 1.125);
  --font-size--16: var(--font-size--base);
  --font-size--14: calc(var(--font-size--base) * 0.875);
  --font-size--13: calc(var(--font-size--base) * 0.8125);
  --font-size--12: calc(var(--font-size--base) * 0.75);
  --font-size--11: calc(var(--font-size--base) * 0.6875);
  --font-size--10: calc(var(--font-size--base) * 0.625);
  --font-size--9: calc(var(--font-size--base) * 0.5625);
  --font-size--display-1: calc(var(--font-size--base) * 4);
  --font-size--display-2: calc(var(--font-size--base) * 3.5);
  --font-size--h1: calc(var(--font-size--base) * 2.5);
  --font-size--h2: calc(var(--font-size--base) * 2);
  --font-size--h3: calc(var(--font-size--base) * 1.75);
  --font-size--h4: calc(var(--font-size--base) * 1.5);
  --font-size--h5: calc(var(--font-size--base) * 1.25);
  --font-size--h6: calc(var(--font-size--base) * 1.125);
  --font-size--lead: var(--font-size--base);
  --font-size--lead-small: var(--font-size--14);
  --font-size--body: var(--font-size--14);
  --font-size--body-large: var(--font-size--base);
  --font-size--data: calc(var(--font-size--base) * 0.75);
  --font-size--data--smal: calc(var(--font-size--base) * 0.6875);
  --line-height--base: 1.2;
  --line-height--body: 24px;
  --line-height--display-1: 1.4;
  --line-height--display-2: 1.2143;
  --font-weight--extra-black: 950;
  --font-weight--black: 900;
  --font-weight--extra-bold: 800;
  --font-weight--bold: 700;
  --font-weight--semi-bold: 600;
  --font-weight--medium: 500;
  --font-weight--regular: 400;
  --font-weight--light: 300;
  --font-weight--extra-light: 200;
  --font-weight--thin: 100;
  --color-brand-blue: rgba(37, 79, 158, 1);
  --color-brand-dark: rgba(18, 17, 15, 1);
  --color-brand-grey: rgba(148, 149, 149, 1);
  --color-black: #000;
  --color-black-text: rgba(51, 51, 51, 1);
  --color-grey-900: rgba(33, 41, 52, 1);
  --color-grey-800: rgba(74, 79, 93, 1);
  --color-grey-700: rgba(90, 97, 117, 1);
  --color-grey-600: rgba(109, 120, 149, 1);
  --color-grey-500: rgba(132, 143, 170, 1);
  --color-grey-400: rgba(171, 178, 195, 1);
  --color-grey-300: rgba(193, 199, 210, 1);
  --color-grey-200: rgba(209, 213, 223, 1);
  --color-grey-100: rgba(230, 233, 240, 1);
  --color-grey-50: rgba(245, 246, 250, 1);
  --color-white: #fff;
  --color-blue-700: rgba(25, 63, 132, 1);
  --color-blue-600: rgba(19, 83, 202, 1);
  --color-blue-400: rgba(69, 144, 247, 1);
  --color-blue-300: rgba(118, 166, 254, 1);
  --color-blue-200: rgba(174, 202, 255, 1);
  --color-blue-150: rgba(205, 219, 244, 1);
  --color-blue-100: rgba(226, 236, 254, 1);
  --color-blue-50: rgba(241, 249, 255, 1);
  --color-green: rgba(44, 133, 2, 1);
  --color-green-700: rgba(10, 113, 85, 1);
  --color-orange-700: rgba(210, 57, 15, 1);
  --color-red-400: rgba(248, 94, 96, 1);
  --accent-color--green: rgba(2, 138, 15, 1);
  --accent-color--orange: rgba(240, 107, 0, 1);
  --accent-color--red: rgba(183, 18, 52, 1);
  --accent-color--purple: rgba(139, 99, 244, 1);
  --background-color--light-100: rgba(245, 246, 250, 1);
  --background-color--light-200: rgba(242, 243, 248, 1);
  --background-color--light-300: rgba(227, 229, 237, 1);
  --background-color--light-400: rgba(194, 203, 220, 1);
  --overlay-background-color--light: rgba(33, 41, 52, 0.08);
  --overlay-background-color--dark: rgba(33, 41, 52, 0.3);
  --overlay-background-color--darker: rgba(33, 41, 52, 0.4);
  --overlay-dialog: rgba(0, 0, 0, 0.5);
  --color-primary-blue: rgba(4, 111, 210, 1);
  --color-primary-ink: rgba(33, 41, 52, 1);
  --box-shadow--800: 0px 8px 28px -6px rgba(24, 39, 75, 0.12), 0px 18px 88px -4px rgba(24, 39, 75, 0.14);
  --box-shadow--700: 0px 8px 22px -6px rgba(24, 39, 75, 0.12), 0px 14px 64px -4px rgba(24, 39, 75, 0.12);
  --box-shadow--600: 0px 8px 18px -6px rgba(24, 39, 75, 0.12), 0px 12px 42px -4px rgba(24, 39, 75, 0.12);
  --box-shadow--500: 0px 6px 14px -6px rgba(24, 39, 75, 0.12), 0px 10px 32px -4px rgba(24, 39, 75, 0.1);
  --box-shadow--400: 0px 6px 12px -6px rgba(24, 39, 75, 0.12), 0px 8px 24px -4px rgba(24, 39, 75, 0.08);
  --box-shadow--300: 0px 6px 8px -6px rgba(24, 39, 75, 0.12), 0px 8px 16px -6px rgba(24, 39, 75, 0.08);
  --box-shadow--200: 0px 4px 6px -4px rgba(24, 39, 75, 0.12), 0px 8px 8px -4px rgba(24, 39, 75, 0.08);
  --box-shadow--100: 0px 2px 4px -2px rgba(24, 39, 75, 0.12), 0px 4px 4px -2px rgba(24, 39, 75, 0.08);
}

.form-lede {
  align-items: center;
  display: flex;
  margin-bottom: 16px;
  color: var(--text-color);
  font-family: var(--font-family--body);
  font-size: 16px;
  font-style: normal;
  font-weight: bold;
  line-height: 20px;
}
.form-lede svg {
  width: 22px;
  fill: var(--primary-background);
  margin-right: 6px;
}

.form-control {
  background-clip: padding-box;
  display: block;
  min-height: 32px;
  position: relative;
  width: 100%;
  background-color: var(--input-background-color);
  border: 1px solid var(--input-border-color);
  border-radius: 4px;
  /* ---------- States ---------- */
  /* ---------- Sizes ---------- */
}
.form-control input, .form-control textarea {
  display: block;
  outline: none;
  padding: 3px 9px;
  width: 100%;
  color: var(--input-color);
  font-family: var(--font-family--body);
  font-size: var(--font-size--14);
  font-weight: var(--font-weight--regular);
  line-height: 24px;
  background-color: transparent !important;
  border: none;
  border-radius: 4px;
}
.form-control input:-webkit-autofill,
.form-control input:-webkit-autofill:hover,
.form-control input:-webkit-autofill:focus,
.form-control input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--input-background-color) inset !important;
}
.form-control:hover {
  background-color: var(--input-background-color--hover);
  border-color: var(--input-border-color--hover);
}
.form-control:hover input {
  color: var(--input-color--hover);
}
.form-control:focus-within {
  background-color: var(--input-background-color--focus);
  border-color: var(--input-border-color--focus);
}
.form-control:focus-within input {
  color: var(--input-color--focus);
}
.form-control:disabled, .form-control.disabled {
  background-color: var(--input-background-color--disabled);
  border-color: var(--input-border-color--disabled);
}
.form-control:disabled input, .form-control.disabled input {
  color: var(--input-color--disabled);
}
.form-control.invalid {
  border-color: var(--accent-color--red);
}
.form-control.small {
  height: 28px;
}
.form-control.small.invalid::after {
  top: 5px;
}
.form-control.small input {
  font-size: var(--font-size--12);
  line-height: 1.3333;
  padding: 5.5px 10px;
}
.form-control.small input:disabled {
  padding: 6.5px 11px;
}
.form-control.large {
  height: 40px;
}
.form-control.large.invalid::after {
  top: 11px;
}
.form-control.large input {
  font-size: 14px;
  line-height: 24px;
  padding: 7px 10px;
}
.form-control.large input:disabled {
  padding: 8px 11px;
}

.form-control-plaintext {
  display: block;
  height: 32px;
  padding: 9px 11px;
  width: 100%;
  font-family: var(--font-family--body);
  font-size: var(--font-size--12);
  font-weight: var(--font-weight--regular);
  line-height: 1.3333;
  background-color: transparent;
  border: 0 solid transparent;
  color: var(--input-color);
}
.form-control-plaintext.small {
  height: 28px;
  font-size: var(--font-size--12);
  line-height: 1.3333;
  padding: 6.5px 11px;
}
.form-control-plaintext.large {
  height: 40px;
  font-size: var(--font-size--14);
  line-height: 1.7143;
  padding: 8px 11px;
}

.form-group {
  margin-bottom: 0.5rem;
}
.form-group label {
  color: var(--color-grey-800);
  display: block;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.015em;
  line-height: 16px;
  margin-bottom: 8px;
  text-transform: uppercase;
}
.form-group label.required:after {
  color: var(--accent-color--red);
  content: "*";
  margin-left: 2px;
}
.form-group label.invalid {
  color: var(--accent-color--red);
}

.form-message {
  font-size: 12px;
  line-height: 18px;
  margin-top: 4px;
  min-height: 18px;
  position: relative;
}
.form-message .error {
  color: var(--accent-color--red);
}
.form-message .error svg {
  height: 16px;
  width: 16px;
  fill: var(--accent-color--red);
  margin: 1px 4px 1px 0;
}
.form-message .info {
  color: var(--color-grey-700);
}
.form-message .info svg {
  height: 16px;
  width: 16px;
  fill: var(--color-primary-blue);
  margin: 1px 4px 1px 0;
}

.row {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

textarea.form-control {
  height: auto;
}

:root[data-theme=theme-light] {
  --text-color: var(--color-black-text);
  --background-color: var(--color-white);
  --border-color: var(--color-grey-200);
  --default-background: var(--color-white);
  --default-color: var(--color-black);
  --default-border-color: var(--color-black);
  --default-border-radius: 8px;
  --default-border-width: 1px;
  --default-border-width--focus: 1px;
  --input-color: var(--color-grey-900);
  --input-background-color: var(--color-grey-50);
  --input-border-color: var(--color-grey-300);
  --input-color--hover: var(--color-primary-ink);
  --input-background-color--hover: var(--color-grey-50);
  --input-border-color--hover: var(--color-primary-ink);
  --input-color--focus: var(--color-primary-ink);
  --input-background-color--focus: var(--color-white);
  --input-border-color--focus: var(--color-primary-ink);
  --input-color--disabled: var(--color-grey-900);
  --input-background-color--disabled: var(--color-grey-50);
  --input-border-color--disabled: var(--color-grey-50);
  --primary-background: var(--color-primary-blue);
  --primary-color: var(--color-white);
  --primary-border-color: var(--color-primary-blue);
  --primary-background--hover: var(--color-blue-600);
  --primary-color--hover: var(--color-white);
  --primary-border-color--hover: var(--color-blue-600);
  --primary-background--focus: var(--color-blue-700);
  --primary-color--focus: var(--color-white);
  --primary-border-color--focus: var(--color-blue-700);
  --primary-background--inactive: var(--color-blue-200);
  --primary-color--inactive: var(--color-white);
  --primary-border-color--inactive: var(--color-blue-200);
  --secondary-background: var(--color-white);
  --secondary-color: var(--color-primary-blue);
  --secondary-border-color: var(--color-primary-blue);
  --secondary-background--hover: var(--color-grey-50);
  --secondary-color--hover: var(--color-primary-blue);
  --secondary-border-color--hover: var(--color-blue-600);
  --secondary-background--focus: var(--color-white);
  --secondary-color--focus: var(--color-primary-blue);
  --secondary-border-color--focus: var(--color-blue-600);
  --secondary-background--inactive: var(--color-white);
  --secondary-color--inactive: var(--color-blue-200);
  --secondary-border-color--inactive: var(--color-blue-200);
  --tertiary-background: var(--color-blue-100);
  --tertiary-color: var(--color-primary-blue);
  --tertiary-border-color: var(--color-blue-100);
  --tertiary-background--hover: var(--color-blue-150);
  --tertiary-color--hover: var(--color-primary-blue);
  --tertiary-border-color--hover: var(--color-blue-150);
  --tertiary-background--focus: var(--color-blue-100);
  --tertiary-color--focus: var(--color-primary-blue);
  --tertiary-border-color--focus: var(--color-blue-100);
  --tertiary-background--inactive: var(--color-blue-50);
  --tertiary-color--inactive: var(--color-blue-200);
  --tertiary-border-color--inactive: var(--color-blue-50);
}

:root[data-theme=theme-dark] {
  --text-color: var(--color-white);
  --background-color: var(--color-grey-900);
  --border-color: var(--color-grey-200);
  --default-background: var(--color-white);
  --default-color: var(--color-black);
  --default-border-color: var(--color-white);
  --default-border-radius: 8px;
  --default-border-width: 1px;
  --default-border-width--focus: 1px;
  --input-color: var(--color-grey-200);
  --input-background-color: var(--color-black);
  --input-border-color: var(--color-grey-500);
  --input-color--hover: var(--color-white);
  --input-background-color--hover: var(--color-black);
  --input-border-color--hover: var(--color-primary-ink);
  --input-color--focus: var(--color-white);
  --input-background-color--focus: var(--color-black);
  --input-border-color--focus: var(--color-primary-ink);
  --input-color--disabled: var(--color-grey-200);
  --input-background-color--disabled: var(--color-grey-700);
  --input-border-color--disabled: var(--color-grey-700);
  --primary-background: var(--color-primary-blue);
  --primary-color: var(--color-white);
  --primary-border-color: var(--color-primary-blue);
  --primary-background--hover: var(--color-blue-600);
  --primary-color--hover: var(--color-white);
  --primary-border-color--hover: var(--color-blue-600);
  --primary-background--focus: var(--color-blue-700);
  --primary-color--focus: var(--color-white);
  --primary-border-color--focus: var(--color-blue-700);
  --primary-background--inactive: var(--color-blue-200);
  --primary-color--inactive: var(--color-grey-500);
  --primary-border-color--inactive: var(--color-blue-200);
  --secondary-background: var(--color-grey-900);
  --secondary-color: var(--color-primary-blue);
  --secondary-border-color: var(--color-primary-blue);
  --secondary-background--hover: var(--color-grey-700);
  --secondary-color--hover: var(--color-primary-blue);
  --secondary-border-color--hover: var(--color-blue-600);
  --secondary-background--focus: var(--color-grey-900);
  --secondary-color--focus: var(--color-primary-blue);
  --secondary-border-color--focus: var(--color-blue-600);
  --secondary-background--inactive: var(--color-grey-900);
  --secondary-color--inactive: var(--color-primary-blue);
  --secondary-border-color--inactive: var(--color-grey-500);
  --tertiary-background: var(--color-grey-900);
  --tertiary-color: var(--color-primary-blue);
  --tertiary-border-color: var(--color-grey-900);
  --tertiary-background--hover: var(--color-grey-700);
  --tertiary-color--hover: var(--color-primary-blue);
  --tertiary-border-color--hover: var(--color-grey-700);
  --tertiary-background--focus: var(--color-grey-900);
  --tertiary-color--focus: var(--color-primary-blue);
  --tertiary-border-color--focus: var(--color-grey-900);
  --tertiary-background--inactive: var(--color-grey-900);
  --tertiary-color--inactive: var(--color-grey-500);
  --tertiary-border-color--inactive: var(--color-grey-900);
}

.pki-data-grid {
  height: 100%;
  width: 100%;
}
.pki-data-grid .MuiDataGrid-root {
  border: none;
  font-family: var(--font-family--body);
  font-weight: var(--font-weight--regular);
  font-size: var(--font-size--14);
}
.pki-data-grid .MuiDataGrid-root .MuiDataGrid-columnHeaders {
  background-color: var(--color-grey-50);
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  min-height: 0 !important;
}
.pki-data-grid .MuiDataGrid-root .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeader {
  padding: 0px 12px;
}
.pki-data-grid .MuiDataGrid-root .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeader:focus {
  outline: 0;
}
.pki-data-grid .MuiDataGrid-root .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeader .MuiDataGrid-menuIcon {
  font-size: var(--font-size--12);
}
.pki-data-grid .MuiDataGrid-root .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeader .MuiDataGrid-columnSeparator {
  min-height: 0 !important;
  visibility: hidden;
}
.pki-data-grid .MuiDataGrid-root .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeader .MuiDataGrid-columnSeparator .MuiSvgIcon-fontSizeMedium {
  height: 16px;
  width: 16px;
}
.pki-data-grid .MuiDataGrid-root .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeader .MuiDataGrid-columnHeaderTitleContainer {
  padding: 0;
}
.pki-data-grid .MuiDataGrid-root .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeader .MuiDataGrid-columnHeaderTitleContainer .MuiDataGrid-columnHeaderTitle {
  font-weight: var(--font-weight--regular);
}
.pki-data-grid .MuiDataGrid-root .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeader .MuiButtonBase-root {
  font-size: var(--font-size--12);
  padding: 0;
}
.pki-data-grid .MuiDataGrid-root .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeader .MuiButtonBase-root .MuiSvgIcon-root {
  height: 16px;
  width: 16px;
}
.pki-data-grid .MuiDataGrid-root .MuiDataGrid-row {
  align-items: center;
  border-bottom-color: var(--color-grey-100);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  min-height: 0 !important;
  padding-left: 4px !important;
}
.pki-data-grid .MuiDataGrid-root .MuiDataGrid-row .MuiDataGrid-cell {
  border: none;
  outline: 0;
  padding: 6px 8px;
}
.pki-data-grid .MuiDataGrid-root .MuiDataGrid-footerContainer {
  background-color: var(--color-grey-50);
  border-top: 1px solid var(--color-grey-200);
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  min-height: 0 !important;
  padding: 0 18px;
}
.pki-data-grid .MuiDataGrid-root .MuiDataGrid-footerContainer .MuiDataGrid-selectedRowCount {
  line-height: 2;
  margin: 0;
}

.MuiDataGrid-menu .MuiPaper-root {
  border-color: var(--border-color);
  border-style: solid;
  border-width: 1px;
  box-shadow: none;
  filter: drop-shadow(0px 3px 8px rgba(0, 0, 0, 0.2));
}
.MuiDataGrid-menu .MuiMenuItem-root {
  font-size: var(--font-size--12);
  line-height: 24px;
  padding: 0 12px;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
  margin: 0;
  overflow: hidden;
  padding: 0;
}

#root {
  background-color: var(--background-color);
  color: var(--text-color);
  font-family: var(--font-family--body);
  font-size: var(--font-size--14);
  font-weight: var(--font-weight--regular);
  line-height: 1.25;
}
#root.theme-dark, #root.theme-light {
  transition: all 0.5s;
}
#root .pki-title {
  font-size: var(--font-size--h4);
  font-weight: var(--font-weight--regular);
  padding: 0;
  margin: 0;
}
#root .pki-lede {
  align-items: baseline;
  display: flex;
}
#root .pki-button-lede {
  align-items: baseline;
  display: flex;
  justify-content: space-between;
}
#root .pki-back-btn {
  cursor: pointer;
  height: 16px;
  width: 16px;
}

/* ---------- General content styles ---------- */
header,
.header {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 8px;
}
header .pki-header-slot,
.header .pki-header-slot {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
header .pki-page-title,
.header .pki-page-title {
  flex: 0 1 auto;
  font-size: var(--font-size--h4);
  margin-right: 12px;
}

.content {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100%;
}

.content-inner-container {
  background-color: var(--background-color);
  border-radius: 16px;
  display: flex;
  flex: 2 1 auto;
  justify-content: space-between;
  gap: 16px;
  margin: 19px 0 28px;
  padding: 24px;
}
.content-inner-container section {
  border-radius: 16px;
}
.content-inner-container section.list {
  background-color: var(--background-color);
  border-color: var(--border-color);
  border-style: solid;
  border-width: 1px;
  flex: 1.22 1 0;
}
.content-inner-container section.item {
  background-color: var(--color-grey-50);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 24px;
  flex: 1 1 0;
}
.content-inner-container section.item.form {
  background-color: var(--background-color);
  border-color: var(--border-color);
  border-style: solid;
  border-width: 1px;
  padding: 16px;
  justify-content: flex-start;
}
.content-inner-container section.item.form .form-header {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: space-between;
  margin-bottom: 12px;
}
.content-inner-container section.item.form .form-header .form-title {
  font-weight: var(--font-weight--bold);
  flex: 2 1 0;
  white-space: nowrap;
}
.content-inner-container section.item.form .form-header .form-actions {
  display: flex;
  gap: 8px;
}
.content-inner-container section.item .caption-container {
  text-align: center;
  fill: var(--color-blue-300);
}
.content-inner-container section.item .caption-container .caption {
  color: var(--color-grey-700);
  font-weight: var(--font-weight--bold);
  margin-top: 24px;
}

/* ---------- Column auto-layout styles ---------- */
.col-container {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  justify-content: space-between;
}
.col-container.bottom-border {
  border-bottom: 1px solid var(--border-color);
  margin-bottom: 24px;
}
.col-container .col {
  flex: 1 1 0;
  min-width: 100px;
}
.col-container .col2 {
  flex: 2 2 0;
  min-width: 100px;
}

.filter-group {
  font-family: var(--font-family--body);
  font-size: var(--font-size--12);
  line-height: 16px;
  text-transform: uppercase;
}

/* ---------- No Access and Not Found pages ---------- */
.single-page {
  padding-top: 40px;
  text-align: center;
}
.single-page .header-container {
  align-items: center;
  display: flex;
  justify-content: center;
}
.single-page .alert-icon {
  color: var(--accent-color--red);
  height: 42px;
  width: 42px;
  margin-right: 8px;
}
.single-page .alert-icon svg {
  fill: var(--accent-color--red);
}